<template>
  <div class="historyList">
    <music-list
      :list="historyList"
      list-type="delable"
      @select="selectItem"
      @del="deleteItem"
    >
      <div slot="listBtn" class="list-btn">
        <span class="music-btn" @click="$refs.dialog.show()">清空所有</span>
      </div>
    </music-list>
    <music-dialog
      ref="dialog"
      body-text="是否清空播放历史列表"
      confirm-btn-text="清空"
      @confirm="clearList"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MusicList from 'components/music-list/music-list'
import MusicDialog from 'components/music-common/dialog/music-dialog'

export default {
  name: 'HistoryList',
  components: {
    MusicList,
    MusicDialog
  },
  computed: {
    ...mapGetters(['historyList', 'playing', 'currentMusic'])
  },
  methods: {
    // 清空列表事件
    clearList() {
      this.clearHistory()
      this.$Toast('列表清空成功')
    },
    // 播放事件
    selectItem(item, index) {
      this.selectPlay({
        list: this.historyList,
        index
      })
    },
    // 删除事件
    deleteItem(index) {
      let list = [...this.historyList]
      list.splice(index, 1)
      this.removeHistory(list)
      this.$Toast('移除成功')
    },
    ...mapMutations({
      setPlaying: 'SET_PLAYING'
    }),
    ...mapActions(['selectPlay', 'clearHistory', 'removeHistory'])
  }
}
</script>

<style lang="less" scoped>
.historyList {
  width: 100%;
  height: 100%;
  .musicList {
    width: 100%;
    height: 100%;
    .list-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      span {
        padding: 5px 20px;
        cursor: pointer;
        user-select: none;
        &:hover {
          color: @text_color_active;
        }
      }
    }
  }
}
::-webkit-scrollbar-thumb {
  visibility: hidden;
  background: #757F9A;
  background: -webkit-linear-gradient(to right, #D7DDE8, #757F9A);
  background: linear-gradient(to right, #D7DDE8, #757F9A);
  border-radius: 10px;
  transition: all 0.8s;
}
.historyList:hover ::-webkit-scrollbar-thumb {
  visibility: visible;
}
</style>
